import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, Modal, IconButton, Backdrop, useMediaQuery, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function QuiltedImageList({ imageUrls = [] }) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);

    const handleOpen = (index) => {
        setSelectedImageIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImageIndex(0);
    };

    const handleNext = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    };

    const handlePrevious = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
        );
    };

    const formatImageData = (urls) => {
        const slicedUrl = urls.slice(0, 3);
        const formattedData = slicedUrl.map((url, index) => {
            let cols = 1, rows = 1;
            switch (slicedUrl.length) {
                case 1:
                    if (isMobile) {
                        cols = 3;
                        rows = 1;
                    } else {
                        cols = 3;
                        rows = 2;
                    }
                    break;
                case 2:
                    if (isMobile) {
                        cols = index === 0 ? 2 : 1;
                        rows = 1;
                    } else {
                        cols = index === 0 ? 2 : 1;
                        rows = 2;
                    }
                    break;
                case 3:
                    if (isMobile) {
                        if (index === 0) {
                            cols = 3;
                            rows = 1;
                        } else {
                            if (index === 2) {
                                cols = 1;
                                rows = 1;
                            } else {
                                cols = 2;
                                rows = 1;
                            }
                        }
                    } else {
                        if (index === 0) {
                            cols = 2;
                            rows = 2;
                        } else {
                            cols = 1;
                            rows = 1;
                        }
                    }
                    break;

                default:
                    break;
            }
            return { img: url, cols, rows };
        });

        return formattedData;
    };

    const itemData = formatImageData(imageUrls);

    return (
        <Box sx={{ textAlign: 'center' }}>
            <ImageList
                sx={{
                    width: '100%',
                    height: 'auto',
                    gridTemplateColumns: 'repeat(3, 1fr)!important',
                    gap: 10,
                    position: 'relative', 
                }}
                variant="quilted"
                cols={3}
                rowHeight={180}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 10,
                        left: 10,
                        zIndex: 2,
                    }}
                >
                    <Button
                        onClick={() => setOpen(true)}
                        sx={{
                            backgroundColor: 'white',
                            color: '#2E263DE6',
                            padding: '8px 16px',
                            fontSize: '0.875rem',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                            },
                            textTransform: 'capitalize',
                        }}
                    >
                        Ver galeria
                    </Button>
                </Box>

                {itemData.slice(0, 3).map((item, index) => (
                    <ImageListItem key={item.img} cols={item.cols} rows={item.rows}>
                        <Box
                            onClick={() => handleOpen(index)}
                            sx={{
                                backgroundImage: `url(${item.img})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                cursor: 'pointer',
                                width: '100%',
                                height: '100%',
                            }}
                            role="img"
                            aria-label={`Image ${index + 1}`}
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Modal
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                }}
            >
                <Backdrop
                    open={open}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        zIndex: 1,
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            color: 'white',
                            fontSize: 24,
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>

                    <IconButton
                        sx={{
                            position: 'absolute',
                            left: 20,
                            color: 'white',
                            fontSize: 32,
                        }}
                        onClick={handlePrevious}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>

                    <img
                        src={imageUrls[selectedImageIndex]}
                        alt=""
                        style={{
                            maxWidth: '80%',
                            maxHeight: '80vh',
                            objectFit: 'contain',
                            margin: 'auto',
                        }}
                    />

                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 20,
                            color: 'white',
                            fontSize: 32,
                        }}
                        onClick={handleNext}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Backdrop>
            </Modal>
        </Box>
    );
}
