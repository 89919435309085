import { useMediaQuery, Box } from "@mui/material";
import { useState, useEffect } from 'react';
import LineDivider from "./utils/LineDivider";

const SocialNetworkSection = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const calculateWidth = (baseChValue) => {
        const chInPixels = 8;
        const baseWidthInPixels = baseChValue * chInPixels;
        const adjustedWidthInPixels = baseWidthInPixels - (screenWidth * 0.1);
        const adjustedWidthInCh = adjustedWidthInPixels / chInPixels;
        return `${adjustedWidthInCh}ch`;
    };

    return (
        <>

            <Box sx={{
                maxHeight: '300px',
                paddingBottom: '4px',
                mt: 14,
                mb: 5,
            }}>
                <LineDivider />
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {
                            width: isMobile ? calculateWidth(35) : '55ch',
                            mt: 1,
                        },
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mr: isMobile ? 6 : 14,
                        mb: 2,
                        mt: 2   
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <img
                        src="/static/images/facebook-svgrepo-com.png"
                        alt="Facebook Icon"
                        style={{ width: '24px', height: '24px', marginRight: '10px' }}
                    />
                    <img
                        src="/static/images/instagram-svgrepo-com.png"
                        alt="Instagram Icon"
                        style={{ width: '24px', height: '24px', marginRight: '10px' }}
                    />
                    <img
                        src="/static/images/icons8-twitterx-250.png"
                        alt="Twitter Icon"
                        style={{ width: '20px', height: '20px', marginTop: '2px' }}
                    />
                </Box>
                <LineDivider />
            </Box>

        </>
    );
};

export default SocialNetworkSection;
