import { useState, useRef, useEffect } from 'react';
import { Typography, Paper, IconButton, Box, Snackbar, Alert, useMediaQuery } from "@mui/material";
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import imageCompression from 'browser-image-compression';


const BoxPaperPhoto = ({ imagesBase64, imagesBase64Prop }) => {
    const [failedImageSize, setFailedImageSize] = useState(false);
    const [images, setImages] = useState([]);
    const fileInputRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        if (imagesBase64) {
            setImages(imagesBase64);
        }
    }, [imagesBase64]);

    const anyImageFormatToWebp = async (file) => {
        const options = {
            useWebP: true,
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            initialQuality: 0.5,
        };
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
    };
    const handleFileChangeBoxPaperPhoto = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSize = file.size;

            const isValidFileSize = fileSize <= 5 * 1024 * 1024;

            if (!isValidFileSize) {
                setFailedImageSize(true);
                return;
            }
            const compressedFile = await anyImageFormatToWebp(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = reader.result;
                setImages((prevImages) => {
                    const updatedImages = [...prevImages, newImage];
                    imagesBase64Prop(updatedImages);
                    return updatedImages;
                });
            };
            reader.readAsDataURL(compressedFile);
        }
    };

    const handleImageDelete = (indexToDelete) => {
        setImages((prevImages) => {
            const updatedImages = prevImages.filter((_, index) => index !== indexToDelete);
            imagesBase64Prop(updatedImages);
            return updatedImages;
        });
    };


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& > :not(style)': {
                        m: 1,
                        width: 128,
                        height: 128,
                    },
                }}
            >
                {images.map((imageSrc, index) => (
                    <Paper
                        key={index}
                        elevation={1}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            backgroundImage: `url(${imageSrc})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <IconButton
                            aria-label="delete-photo"
                            onClick={() => handleImageDelete(index)}
                            sx={{
                                position: 'absolute',
                                bottom: 8,
                                right: 8,
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                padding: '4px',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255,255,255,0.8)',
                                },
                            }}
                        >
                            <DeleteOutlinedIcon />
                        </IconButton>

                    </Paper>
                ))}

                {images.length < 6 && (
                    <Paper
                        elevation={1}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <IconButton
                            aria-label="add-photo"
                            onClick={() => fileInputRef.current.click()}
                        >
                            <AddAPhotoOutlinedIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography variant="caption" sx={{ color: 'gray' }}>
                            Adicionar foto
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'lightgray' }}>
                            máximo de 6 fotos
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'lightgray' }}>
                            até 5MB
                        </Typography>
                        <input
                            ref={fileInputRef}
                            id="box-paper-photo"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileChangeBoxPaperPhoto}
                        />
                    </Paper>
                )}

            </Box>
            {failedImageSize && (
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{
                        vertical: isMobile ? 'top' : 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Tamanho da foto dever ser menor que 5MB
                    </Alert>
                </Snackbar>
            )}

        </>
    );
};

export default BoxPaperPhoto;