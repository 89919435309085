import { useMediaQuery, Box, Typography } from "@mui/material";

const ThereIsNothingHere = (
    {
        title,
        subtitle
    }
) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: isMobile ? '380px' : '350px',
                mt: 2,
                ml: isMobile ? 5 : 10,
                mr: isMobile ? 5 : 10,
                textAlign: isMobile ? 'center' : 'left'
            }}>

                <img
                    src="/static/images/listening_5901520.png"
                    alt=""
                    style={{ width: '256px', height: '256px', marginTop: '2px' }}
                />
                <Box sx={{ ml: isMobile ? 0 : 2, textAlign: isMobile ? 'center' : 'left' }}>
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{ color: '#2e263de6', marginBottom: 1 }}
                    >
                        {title}
                    </Typography>

                    <Typography
                        variant="subtitle1"
                        sx={{ color: '#2e263db3' }}
                    >
                        {subtitle}
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default ThereIsNothingHere;