import React, { useState, memo, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import LineDividers from '../utils/LineDivider';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

const Comment = memo(({ commentary }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const commentToDisplay = isExpanded
    ? commentary.comment
    : commentary.comment.length > 300
      ? commentary.comment.slice(0, 300) + '...'
      : commentary.comment;

  return (
    <Box
      sx={{
        border: '1px solid lightgray',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
        backgroundColor: 'white',
        textAlign: 'left',
      }}
    >
      <Typography variant="body1" fontWeight="bold">
        {commentary.nickname}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {format(new Date(commentary.created_at), 'dd \'de\' MMMM \'de\' yyyy', { locale: pt })}
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '8px' }}>
        {commentToDisplay}
        {commentary.comment.length > 300 && !isExpanded && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setIsExpanded(true)}
          >
            {' '}
            <span style={{ color: '#614ED9' }}>Ler mais</span>
          </span>
        )}
        {isExpanded && (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setIsExpanded(false)}
          >
            {' '}
            <span style={{ color: '#614ED9' }}>Ler menos</span>
          </span>
        )}
      </Typography>
    </Box>
  );
});

const UserProfileComments = memo(({ actionUserComments, artist }) => {
  // Memoize the unique comments processing
  const uniqueComments = useMemo(() => {
    if (!artist?.commentaries?.length) return [];
    
    // Use a Set to track seen IDs
    const seen = new Set();
    return artist.commentaries.filter(comment => {
      const duplicate = seen.has(comment._id);
      seen.add(comment._id);
      return !duplicate;
    });
  }, [artist?.commentaries]); // Only recompute when commentaries change

  // Early return if no artist data
  if (!artist) return null;

  return (
    <>
      {actionUserComments === 'showArtistComments' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            ml: 0,
            mt: 4
          }}
        >
          <Typography>
            ({uniqueComments.length}) comentários
          </Typography>
        </Box>
      )}

      <LineDividers />

      <div>
        {uniqueComments.map(commentary => (
          <Comment 
            key={commentary._id} 
            commentary={commentary} 
          />
        ))}
      </div>
    </>
  );
});

// Add display names for better debugging
Comment.displayName = 'Comment';
UserProfileComments.displayName = 'UserProfileComments';

export default UserProfileComments;