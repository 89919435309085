import ArtistList from "../ArtistList";
import { useParams } from 'react-router-dom';

const Favorites = ({
    userRole
}) => {
    const { id: userID } = useParams();
    const actionArtistList = 'favorites';

    return (
        <>
            {userID && (
                <ArtistList
                    actionArtistList={actionArtistList}
                    userID={userID}
                    userRole={userRole}
                />
            )}
        </>
    );
}

export default Favorites;