import { useState } from 'react';
import { Box, Avatar, Typography, Stack, useMediaQuery, Button } from "@mui/material";
import DialogUploader from './DialogUploader';

const BoxProfile = ({ isAuthenticated, user, userRole, isMenuItem, userPicture }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [openPhotoProfileDialog, setOpenPhotoProfileDialog] = useState(false);

    const handleOpenPhotoProfileDialog = (value) => {
        setOpenPhotoProfileDialog(value);
    };


    return (
        <>
            <Stack
                direction={isMenuItem || !isMobile ? 'row' : 'column'}
                alignItems="center"
                spacing={isMenuItem || !isMobile ? 2 : -8}
                sx={{
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        width: isMenuItem ? 48 : 168,
                        height: isMenuItem ? 48 : (!isMobile ? 98 : 168),
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        position: 'relative',
                        zIndex: isMenuItem ? 1 : 10,
                        top: isMenuItem ? 'auto' : -60,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {!isMenuItem ? (
                        <Box
                            sx={{
                                position: 'relative',
                                width: 158,
                                height: 158,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '-8px',
                                    left: '-8px',
                                    width: 174,
                                    height: 174,
                                    borderRadius: '16px',
                                    backgroundColor: 'white',
                                    zIndex: 0,
                                }}
                            />

                            <Box
                                sx={{
                                    width: 158,
                                    height: 158,
                                    borderRadius: '16px',
                                    backgroundColor: 'gray',
                                    backgroundImage: isAuthenticated && user ? `url(${userPicture})` : 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            />
                        </Box>
                    ) : (
                        <Avatar
                            src={isAuthenticated && user ? userPicture : ''}
                            sx={{
                                width: isMenuItem ? 48 : 158,
                                height: isMenuItem ? 48 : 158,
                            }}
                        />
                    )}

                </Box>


                <Box
                    sx={{
                        flex: 1,
                        overflow: 'hidden',
                        textAlign: 'left',
                    }}
                >
                    {!isMenuItem && (
                        <>

                            <Box
                                sx={{
                                    display: isMobile ? 'flex' : 'block',
                                    justifyContent: isMobile ? 'center' : 'flex-start',
                                    alignItems: isMobile ? 'center' : 'flex-start',
                                    height: isMobile ? '100%' : 'auto',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={
                                        () => {
                                            handleOpenPhotoProfileDialog(true)
                                        }
                                    }
                                    sx={{
                                        mt: 1,
                                        textTransform: 'capitalize',
                                        backgroundColor: '#614ED9'
                                    }}
                                >
                                    envie nova foto
                                </Button>
                            </Box>

                            <Box
                                sx={{
                                    display: isMobile ? 'flex' : 'block',
                                    justifyContent: isMobile ? 'center' : 'flex-start',
                                    alignItems: isMobile ? 'center' : 'flex-start',
                                    height: isMobile ? '100%' : 'auto',
                                }}
                            >
                                <Typography
                                    variant={"caption"}
                                    sx={{
                                        color: '#2E263DB3'
                                    }}
                                >
                                    Tamanho máximo de 5MB
                                </Typography>
                            </Box>

                            {openPhotoProfileDialog && (
                                <>
                                    <DialogUploader
                                        user={user}
                                        openDialogProp={handleOpenPhotoProfileDialog}
                                    />
                                </>
                            )}

                        </>
                    )}
                    <Typography
                        variant={isMenuItem ? "body1" : "h5"}
                        sx={{
                            padding: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            textAlign: 'left',
                            color: '#2e263de6'
                        }}
                    >
                        {
                            isMenuItem && (
                                <>
                                    {isAuthenticated ? `Olá ${user.nickname}!` : 'Olá visitante!'}
                                </>
                            )
                        }

                    </Typography>
                    {isAuthenticated && (
                        <>
                            {isMenuItem && (
                                <>
                                    <Typography
                                        variant={isMenuItem ? "body2" : "h4"}
                                        sx={{
                                            paddingLeft: 1,
                                            color: '#B0B0B0',
                                            fontSize: isMenuItem ? '0.75rem' : '1rem',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {user.name}
                                    </Typography>
                                </>
                            )}
                            {isMenuItem && (
                                <>
                                    <Typography
                                        variant={isMenuItem ? "body2" : "h4"}
                                        sx={{
                                            paddingLeft: 1,
                                            color: '#B0B0B0',
                                            fontSize: isMenuItem ? '0.75rem' : '1rem',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Perfil: {userRole === 'contractor' ? 'Contratante' : userRole === 'artist' ? 'Artista' : userRole}
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Stack>
        </>
    );
};

export default BoxProfile;
