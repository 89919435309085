import { useMediaQuery, Box, Grid, Typography, AppBar, Toolbar } from "@mui/material";

const Footer = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));


    return (
        <>


            <AppBar
                position="static"
                elevation={0} 
                sx={{
                    top: 'auto',
                    bottom: 0,
                    backgroundColor: '#F5F5F5',
                    boxShadow: 'none' 
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        boxShadow: 'none' 
                    }}
                >
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: isMobile ? 'center' : 'flex-end',
                        mt: 2,
                        mb: 2
                    }}
                    >
                        <Grid container spacing={2}
                        >
                            <Grid item xs={12} sm={isMobile ? 12 : 6}>
                                <Box
                                    sx={{
                                        textAlign: 'justify',
                                        color: '#2e263de6',
                                        mt: isMobile ? 1 : 6,
                                        mb: isMobile ? 1 : 2,
                                        ml: isMobile ? 1 : 6,
                                        mr: isMobile ? 1 : 6
                                    }}
                                >
                                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        CONEXXAMUSIC.COM - Copyright 2024. Todos os direitos reservados.
                                    </Typography>

                                </Box>
                            </Grid>


                            <Grid item xs={12} sm={isMobile ? 12 : 6}>
                                <Box
                                    sx={{
                                        textAlign: 'justify',
                                        color: '#2e263de6',
                                        mt: isMobile ? 2 : 4,
                                        mb: isMobile ? 2 : 6,
                                        ml: isMobile ? 1 : 6,
                                        mr: isMobile ? 1 : 6
                                    }}
                                >
                                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        A Conexxa é uma plataforma que conecta contratantes, como donos de estabelecimentos, a artistas — bandas, músicos e DJs — facilitando o processo de contratação e negociação para ambos.
                                    </Typography>
                                </Box>
                            </Grid>


                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>

        </>
    );
};

export default Footer;
