import React, { useState, useCallback } from 'react';
import { Box } from "@mui/material";
import { Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserProfile from './components/profile/UserProfile';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './components/Footer';
import ArtistList from './components/ArtistList';
import SocialNetworkSection from './components/SocialNetworkSection';
import CardDetails from './components/card/CardDetails';
import Favorites from './components/profile/Favorites';
import Comments from './components/profile/Comments';

function App() {
  const [userRole, setUserRole] = useState();
  const [isEmailVerified, setIsEmailVerified] = useState();
  const [isProfileCompleted, setIsProfileCompleted] = useState();
  const [userPicture, setUserPicture] = useState();
  const { isAuthenticated, isLoading } = useAuth0();
  const actionArtistList = 'top5';

  const handleUserData = useCallback((userRole, isEmailVerified, isProfileCompleted, userPicture) => {
    setUserRole(userRole);
    setIsEmailVerified(isEmailVerified);
    setIsProfileCompleted(isProfileCompleted);
    setUserPicture(userPicture);
  }, []);


  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <Router>
      <div className="App">

        <Routes>
          <Route
            path='/'
            element={
              isAuthenticated ? (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <HeroSection
                    isEmailVerified={isEmailVerified}
                    isProfileCompleted={isProfileCompleted}
                  />
                  <ArtistList
                    actionArtistList={actionArtistList}
                  />
                  <SocialNetworkSection />
                  <Footer />

                </>
              ) : (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <HeroSection
                  />
                  <Box sx={{ flex: 1 }}>
                    <ArtistList
                      actionArtistList={actionArtistList}
                    />
                  </Box>
                  <SocialNetworkSection />
                  <Footer />
                </>
              )
            }
          />
          <Route
            path='auth/profile'
            element={
              isAuthenticated ? (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <UserProfile
                    userRole={userRole}
                    userPicture={userPicture}
                    isProfileCompleted={isProfileCompleted}
                  />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path='card/details/:id'
            element={
              isAuthenticated ? (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                    userRole={userRole}
                    userPicture={userPicture}
                  />

                </>
              ) : (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                  />
                </>
              )
            }
          />

          <Route
            path='auth/profile/favorites/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Favorites
                    userRole={userRole}
                  />
                </>
              )
            }
          />

          <Route
            path='auth/profile/comments/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Comments />
                </>
              )
            }
          />
        </Routes>



      </div>
    </Router>

  );
}

export default App;
