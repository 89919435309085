import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, MenuItem, Stack, useMediaQuery, ListItemIcon, Typography, Paper, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Search = () => {
  const [artistProfileSelected, setArtistProfileSelected] = useState("banda");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openTooltip, setOpenTooltip] = useState(false);
  const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
  const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;

  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState("");

  const [musicStyles, setMusicStyles] = useState([]);
  const [musicStyle, setMusicStyle] = useState("");

  const [periods, setPeriods] = useState([]);
  const [period, setPeriod] = useState("");

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleClick = (value) => {
    setArtistProfileSelected(value);
  };

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await fetch(
          `${railwayAPIURL}/api/v1/dictionary/getdictionary?allZones=false`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authKey': apiAuthKey,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch zones');
        }
        const result = await response.json();

        const zonesList = result.data[0]?.countries.flatMap(country =>
          country.provinces.flatMap(province =>
            province.cities.flatMap(city =>
              city.zones.map(zone => ({
                zone,
                country: country.country,
                countryCode: country.code,
                province: province.province,
                provinceCode: province.code,
                city: city.city,
              }))
            )
          )
        );

        setZones(zonesList || []);
        if (zonesList?.length > 0 && !zone) {  
          setZone(zonesList[0].zone); 
        }


      } catch (error) {
        console.error('Error fetching zones:', error);
      }
    };

    fetchZones();
  }, [railwayAPIURL, apiAuthKey, zone]);

  useEffect(() => {
    const fetchMusicStyles = async () => {
      try {
        const response = await fetch(
          `${railwayAPIURL}/api/v1/dictionary/getdictionary?allMusicStyles=true`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authKey': apiAuthKey,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch music styles');
        }
        const result = await response.json();

        const musicStylesList = result.data[0].music_styles;
        setMusicStyles(musicStylesList || []);

        if (musicStylesList?.length > 0 && !musicStyle) {
          setMusicStyle(musicStylesList[0]);
        }

      } catch (error) {
        console.error('Error fetching music styles:', error);
      }
    };

    fetchMusicStyles();
  }, [railwayAPIURL, apiAuthKey, musicStyle]);

  useEffect(() => {
    const fetchMusicStyles = async () => {
      try {
        const response = await fetch(
          `${railwayAPIURL}/api/v1/dictionary/getdictionary?allPeriods=true`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authKey': apiAuthKey,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch periods');
        }
        const result = await response.json();

        const periodsList = result.data[0].period;
        setPeriods(periodsList || []);

        if (periodsList?.length > 0 && !period) {
          setPeriod(periodsList[0]);
        }

      } catch (error) {
        console.error('Error fetching period:', error);
      }
    };

    fetchMusicStyles();
  }, [railwayAPIURL, apiAuthKey, period]);


  return (
    <Box
      sx={{
        position: 'relative',
        justifyContent: 'center',
        paddingTop: '10px',
        border: '1px solid #D3D3D3',
        borderRadius: '8px',
        padding: isMobile ? '36px' : '20px',
        width: '100%',
        boxSizing: 'border-box',
        backgroundImage: 'url(/static/images/wave_pattern.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          zIndex: -2,
        },
        zIndex: 0,
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{
          flexWrap: 'wrap',
          gap: 1,
          alignItems: isMobile ? 'center' : 'center',
          justifyContent: isMobile ? 'center' : 'center',
          width: '90%',
        }}
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          sx={{
            mb: 4,
            textAlign: 'left',
            justifyContent: isMobile ? 'center' : 'center',
            alignItems: isMobile ? 'center' : 'center',
            width: isMobile ? '80%' : '100%',
          }}
        >
          <Stack
            direction={'row'}
          >
            <Typography variant="h6" gutterBottom
              sx={{
                color: 'white',
                fontWeight: 'bold',
                mt: isMobile ? 4 : 1
              }}
            >
              O que você está procurando?
            </Typography>

            {isMobile && (
              <>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Selecione o perfil artístico, região, estilo musical, perído e data para ter uma melhor resultado."

                  >
                    <IconButton
                      size="small"
                      sx={{
                        mt: 8,
                        mb: 8,
                        backgroundColor: 'white',
                        padding: '1px',
                        '&:hover': {
                          backgroundColor: 'lightgray',
                        },
                      }}
                      onClick={handleTooltipOpen}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </>
            )}

          </Stack>


          <Button
            onClick={() => handleClick("banda")}
            sx={{
              minWidth: isMobile ? '100%' : 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid white',
              backgroundColor: artistProfileSelected === "banda" ? 'white' : '#16086E',
              color: artistProfileSelected === "banda" ? '#2812B3' : 'white',
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white',
                color: '#2812B3',
                '& .MuiListItemIcon-root': {
                  color: '#808080',
                },
              },
              '& .MuiListItemIcon-root': {
                color: artistProfileSelected === "banda" ? '#808080' : 'white',
              },
            }}
            aria-label="banda"
          >
            <ListItemIcon>
              <Groups2OutlinedIcon fontSize="medium" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'inherit', textTransform: 'none' }}>
              Banda
            </Typography>
          </Button>

          <Button
            onClick={() => handleClick("musico")}
            sx={{
              minWidth: isMobile ? '100%' : 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid white',
              backgroundColor: artistProfileSelected === "musico" ? 'white' : '#16086E',
              color: artistProfileSelected === "musico" ? '#2812B3' : 'white',
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white',
                color: '#2812B3',
                '& .MuiListItemIcon-root': {
                  color: '#808080',
                },
              },
              '& .MuiListItemIcon-root': {
                color: artistProfileSelected === "musico" ? '#808080' : 'white',
              },
            }}
            aria-label="musico"
          >
            <ListItemIcon>
              <MicExternalOnOutlinedIcon fontSize="medium" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'inherit', textTransform: 'none' }}>
              Músico
            </Typography>
          </Button>

          <Button
            onClick={() => handleClick("dj")}
            sx={{
              minWidth: isMobile ? '100%' : 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid white',
              backgroundColor: artistProfileSelected === "dj" ? 'white' : '#16086E',
              color: artistProfileSelected === "dj" ? '#2812B3' : 'white',
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white',
                color: '#2812B3',
                '& .MuiListItemIcon-root': {
                  color: '#808080',
                },
              },
              '& .MuiListItemIcon-root': {
                color: artistProfileSelected === "dj" ? '#808080' : 'white',
              },
            }}
            aria-label="dj"
          >
            <ListItemIcon>
              <AlbumOutlinedIcon fontSize="medium" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'inherit', textTransform: 'none' }}>
              DJ
            </Typography>
          </Button>

          {!isMobile && (
            <>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Selecione o perfil artístico, região, estilo musical, perído e data para ter uma melhor resultado."

                >
                  <IconButton
                    size="small"
                    sx={{
                      ml: 2,
                      mt: 5,
                      mb: 4,
                      backgroundColor: 'white',
                      padding: '1px',
                      '&:hover': {
                        backgroundColor: 'lightgray',
                      },
                    }}
                    onClick={handleTooltipOpen}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </>
          )}

        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          sx={{
            flexWrap: 'wrap',
            gap: 1,
            alignItems: isMobile ? 'center' : 'center',
            justifyContent: isMobile ? 'center' : 'center',
            width: '100%',
          }}
        >
          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              select
              label="Região"
              value={zone}
              onChange={(e) => setZone(e.target.value)}
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
            >
              {zones.length > 0 ? (
                zones.map((zoneData) => (
                  <MenuItem key={zoneData.zone} value={zoneData.zone}>
                    {`${zoneData.countryCode}, ${zoneData.provinceCode}, ${zoneData.city}, ${zoneData.zone}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  Zonas não disponíveis
                </MenuItem>
              )}
            </TextField>
          </Paper>


          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              select
              label="Estilo Musical"
              value={musicStyle}
              onChange={(e) => setMusicStyle(e.target.value)}
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
            >
              {musicStyles.length > 0 ? (
                musicStyles.map((musicStyleOption) => (
                  <MenuItem key={musicStyleOption} value={musicStyleOption}>
                    {musicStyleOption}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  Estilos Musicais não disponíveis
                </MenuItem>
              )}
            </TextField>
          </Paper>

          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              select
              label="Período"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
            >
              {periods.length > 0 ? (
                periods.map((periodsOption) => (
                  <MenuItem key={periodsOption} value={periodsOption}>
                    {periodsOption}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  Períodos não disponíveis
                </MenuItem>
              )}
            </TextField>
          </Paper>

          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              label="Data do evento"
              type="date"
              defaultValue={new Date().toISOString().split('T')[0]}
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Paper>

          <Button
            variant="contained"
            sx={{
              minWidth: '120px',
              backgroundColor: '#FFCA00',
              color: 'white',
              height: '50px',
              '&:hover': {
                backgroundColor: '#FFCA00',
              },
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              top: '8px',
              fontWeight: 'bold',
            }}
            startIcon={<SearchIcon />}
          >
            Pesquisar
          </Button>




        </Stack>
      </Stack>
    </Box>
  );
};

export default Search;
