import { Divider } from "@mui/material";

const LineDivider = () => {
    return (
        <>
            <Divider
            sx={{
                mt: 1,
                mb: 1
            }}
             />
        </>
    );
};

export default LineDivider;